exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility_statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-adverticement-table-js": () => import("./../../../src/pages/adverticement_table.js" /* webpackChunkName: "component---src-pages-adverticement-table-js" */),
  "component---src-pages-animated-tree-js": () => import("./../../../src/pages/animated_tree.js" /* webpackChunkName: "component---src-pages-animated-tree-js" */),
  "component---src-pages-award-table-js": () => import("./../../../src/pages/award_table.js" /* webpackChunkName: "component---src-pages-award-table-js" */),
  "component---src-pages-beneficiary-js": () => import("./../../../src/pages/Beneficiary.js" /* webpackChunkName: "component---src-pages-beneficiary-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cllmp-cllmp-js": () => import("./../../../src/pages/cllmp/cllmp.js" /* webpackChunkName: "component---src-pages-cllmp-cllmp-js" */),
  "component---src-pages-cllmp-implementation-activities-js": () => import("./../../../src/pages/cllmp_implementation_activities.js" /* webpackChunkName: "component---src-pages-cllmp-implementation-activities-js" */),
  "component---src-pages-cllmp-nrl-planing-js": () => import("./../../../src/pages/cllmp/nrl_planing.js" /* webpackChunkName: "component---src-pages-cllmp-nrl-planing-js" */),
  "component---src-pages-cllmp-publication-js": () => import("./../../../src/pages/cllmp_publication.js" /* webpackChunkName: "component---src-pages-cllmp-publication-js" */),
  "component---src-pages-cllmp-strengthening-capacity-js": () => import("./../../../src/pages/cllmp/strengthening_capacity.js" /* webpackChunkName: "component---src-pages-cllmp-strengthening-capacity-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-green-meghalaya-dashboard-js": () => import("./../../../src/pages/green_meghalaya_dashboard.js" /* webpackChunkName: "component---src-pages-green-meghalaya-dashboard-js" */),
  "component---src-pages-green-meghalaya-js": () => import("./../../../src/pages/green_meghalaya.js" /* webpackChunkName: "component---src-pages-green-meghalaya-js" */),
  "component---src-pages-greenfile-js": () => import("./../../../src/pages/greenfile.js" /* webpackChunkName: "component---src-pages-greenfile-js" */),
  "component---src-pages-grievance-js": () => import("./../../../src/pages/Grievance.js" /* webpackChunkName: "component---src-pages-grievance-js" */),
  "component---src-pages-highlight-js": () => import("./../../../src/pages/highlight.js" /* webpackChunkName: "component---src-pages-highlight-js" */),
  "component---src-pages-image-gallery-js": () => import("./../../../src/pages/image-gallery.js" /* webpackChunkName: "component---src-pages-image-gallery-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infocus-js": () => import("./../../../src/pages/infocus.js" /* webpackChunkName: "component---src-pages-infocus-js" */),
  "component---src-pages-latest-award-js": () => import("./../../../src/pages/latest-award.js" /* webpackChunkName: "component---src-pages-latest-award-js" */),
  "component---src-pages-latest-recruitment-js": () => import("./../../../src/pages/latest-recruitment.js" /* webpackChunkName: "component---src-pages-latest-recruitment-js" */),
  "component---src-pages-latest-result-js": () => import("./../../../src/pages/latest-result.js" /* webpackChunkName: "component---src-pages-latest-result-js" */),
  "component---src-pages-latest-tender-js": () => import("./../../../src/pages/latest-tender.js" /* webpackChunkName: "component---src-pages-latest-tender-js" */),
  "component---src-pages-m-focus-js": () => import("./../../../src/pages/m-focus.js" /* webpackChunkName: "component---src-pages-m-focus-js" */),
  "component---src-pages-main-menu-page-knowledge-js": () => import("./../../../src/pages/main-menu-page/knowledge.js" /* webpackChunkName: "component---src-pages-main-menu-page-knowledge-js" */),
  "component---src-pages-main-menu-page-update-js": () => import("./../../../src/pages/main-menu-page/Update.js" /* webpackChunkName: "component---src-pages-main-menu-page-update-js" */),
  "component---src-pages-main-menu-page-what-we-do-js": () => import("./../../../src/pages/main-menu-page/What_we_do.js" /* webpackChunkName: "component---src-pages-main-menu-page-what-we-do-js" */),
  "component---src-pages-main-menu-page-who-we-are-js": () => import("./../../../src/pages/main-menu-page/Who_we_are.js" /* webpackChunkName: "component---src-pages-main-menu-page-who-we-are-js" */),
  "component---src-pages-mblog-js": () => import("./../../../src/pages/mblog.js" /* webpackChunkName: "component---src-pages-mblog-js" */),
  "component---src-pages-meghalaya-collectives-js": () => import("./../../../src/pages/meghalaya-collectives.js" /* webpackChunkName: "component---src-pages-meghalaya-collectives-js" */),
  "component---src-pages-meghalaya-collectives-stories-js": () => import("./../../../src/pages/meghalaya-collectives-stories.js" /* webpackChunkName: "component---src-pages-meghalaya-collectives-stories-js" */),
  "component---src-pages-mlamp-enterprise-development-js": () => import("./../../../src/pages/mlamp/enterprise_development.js" /* webpackChunkName: "component---src-pages-mlamp-enterprise-development-js" */),
  "component---src-pages-mlamp-mlamp-js": () => import("./../../../src/pages/mlamp/mlamp.js" /* webpackChunkName: "component---src-pages-mlamp-mlamp-js" */),
  "component---src-pages-mlamp-natural-resource-js": () => import("./../../../src/pages/mlamp/natural_resource.js" /* webpackChunkName: "component---src-pages-mlamp-natural-resource-js" */),
  "component---src-pages-mlamp-natural-resource-test-js": () => import("./../../../src/pages/mlamp/natural_resource_test.js" /* webpackChunkName: "component---src-pages-mlamp-natural-resource-test-js" */),
  "component---src-pages-mlamp-publication-js": () => import("./../../../src/pages/mlamp_publication.js" /* webpackChunkName: "component---src-pages-mlamp-publication-js" */),
  "component---src-pages-mlamp-rural-finance-js": () => import("./../../../src/pages/mlamp/rural_finance.js" /* webpackChunkName: "component---src-pages-mlamp-rural-finance-js" */),
  "component---src-pages-mlamp-supply-chain-js": () => import("./../../../src/pages/mlamp/supply_chain.js" /* webpackChunkName: "component---src-pages-mlamp-supply-chain-js" */),
  "component---src-pages-notice-advertisement-js": () => import("./../../../src/pages/notice/advertisement.js" /* webpackChunkName: "component---src-pages-notice-advertisement-js" */),
  "component---src-pages-notice-award-js": () => import("./../../../src/pages/notice/award.js" /* webpackChunkName: "component---src-pages-notice-award-js" */),
  "component---src-pages-notice-display-all-js": () => import("./../../../src/pages/notice_display_all.js" /* webpackChunkName: "component---src-pages-notice-display-all-js" */),
  "component---src-pages-notice-js": () => import("./../../../src/pages/notice.js" /* webpackChunkName: "component---src-pages-notice-js" */),
  "component---src-pages-notice-result-js": () => import("./../../../src/pages/notice/result.js" /* webpackChunkName: "component---src-pages-notice-result-js" */),
  "component---src-pages-notice-tender-js": () => import("./../../../src/pages/notice/tender.js" /* webpackChunkName: "component---src-pages-notice-tender-js" */),
  "component---src-pages-one-blog-js": () => import("./../../../src/pages/one_blog.js" /* webpackChunkName: "component---src-pages-one-blog-js" */),
  "component---src-pages-one-infocus-js": () => import("./../../../src/pages/one-infocus.js" /* webpackChunkName: "component---src-pages-one-infocus-js" */),
  "component---src-pages-payments-for-ecosystem-js": () => import("./../../../src/pages/payments-for-ecosystem.js" /* webpackChunkName: "component---src-pages-payments-for-ecosystem-js" */),
  "component---src-pages-pes-benficial-list-js": () => import("./../../../src/pages/Pes_benficial_list.js" /* webpackChunkName: "component---src-pages-pes-benficial-list-js" */),
  "component---src-pages-pes-pilot-project-js": () => import("./../../../src/pages/pes-pilot-project.js" /* webpackChunkName: "component---src-pages-pes-pilot-project-js" */),
  "component---src-pages-pesdata-js": () => import("./../../../src/pages/pesdata.js" /* webpackChunkName: "component---src-pages-pesdata-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-pmjvm-js": () => import("./../../../src/pages/pmjvm.js" /* webpackChunkName: "component---src-pages-pmjvm-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-progressbar-js": () => import("./../../../src/pages/progressbar.js" /* webpackChunkName: "component---src-pages-progressbar-js" */),
  "component---src-pages-project-doc-js": () => import("./../../../src/pages/project_doc.js" /* webpackChunkName: "component---src-pages-project-doc-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-recruitment-tale-js": () => import("./../../../src/pages/recruitment_tale.js" /* webpackChunkName: "component---src-pages-recruitment-tale-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/Report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-resource-2-js": () => import("./../../../src/pages/resource2.js" /* webpackChunkName: "component---src-pages-resource-2-js" */),
  "component---src-pages-resource-js": () => import("./../../../src/pages/resource.js" /* webpackChunkName: "component---src-pages-resource-js" */),
  "component---src-pages-result-table-js": () => import("./../../../src/pages/result_table.js" /* webpackChunkName: "component---src-pages-result-table-js" */),
  "component---src-pages-screen-reader-js": () => import("./../../../src/pages/screen_reader.js" /* webpackChunkName: "component---src-pages-screen-reader-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-slider-js": () => import("./../../../src/pages/slider.js" /* webpackChunkName: "component---src-pages-slider-js" */),
  "component---src-pages-social-media-facebook-js": () => import("./../../../src/pages/social-media/facebook.js" /* webpackChunkName: "component---src-pages-social-media-facebook-js" */),
  "component---src-pages-social-media-instagram-js": () => import("./../../../src/pages/social-media/instagram.js" /* webpackChunkName: "component---src-pages-social-media-instagram-js" */),
  "component---src-pages-social-media-social-tab-js": () => import("./../../../src/pages/social-media/social_tab.js" /* webpackChunkName: "component---src-pages-social-media-social-tab-js" */),
  "component---src-pages-social-media-twiiter-x-js": () => import("./../../../src/pages/social-media/twiiter_x.js" /* webpackChunkName: "component---src-pages-social-media-twiiter-x-js" */),
  "component---src-pages-social-media-youtube-2-js": () => import("./../../../src/pages/social-media/youtube2.js" /* webpackChunkName: "component---src-pages-social-media-youtube-2-js" */),
  "component---src-pages-social-media-youtube-js": () => import("./../../../src/pages/social-media/youtube.js" /* webpackChunkName: "component---src-pages-social-media-youtube-js" */),
  "component---src-pages-sustainable-land-management-establishing-effective-market-linkages-js": () => import("./../../../src/pages/sustainable_land_management/establishing_effective_market_linkages.js" /* webpackChunkName: "component---src-pages-sustainable-land-management-establishing-effective-market-linkages-js" */),
  "component---src-pages-sustainable-land-management-organic-certification-for-premier-organic-markets-js": () => import("./../../../src/pages/sustainable_land_management/organic_certification_for_premier_organic_markets.js" /* webpackChunkName: "component---src-pages-sustainable-land-management-organic-certification-for-premier-organic-markets-js" */),
  "component---src-pages-sustainable-land-management-organic-farming-production-and-diversification-js": () => import("./../../../src/pages/sustainable_land_management/organic_farming_production_and_diversification.js" /* webpackChunkName: "component---src-pages-sustainable-land-management-organic-farming-production-and-diversification-js" */),
  "component---src-pages-sustainable-land-management-sustainable-land-management-js": () => import("./../../../src/pages/sustainable_land_management/sustainable_land_management.js" /* webpackChunkName: "component---src-pages-sustainable-land-management-sustainable-land-management-js" */),
  "component---src-pages-sustainable-land-management-value-addition-upgradation-of-aggregation-infrastructure-js": () => import("./../../../src/pages/sustainable_land_management/value_addition _&_upgradation _of_aggregation_infrastructure.js" /* webpackChunkName: "component---src-pages-sustainable-land-management-value-addition-upgradation-of-aggregation-infrastructure-js" */),
  "component---src-pages-tender-js": () => import("./../../../src/pages/tender.js" /* webpackChunkName: "component---src-pages-tender-js" */),
  "component---src-pages-tender-table-js": () => import("./../../../src/pages/tender_table.js" /* webpackChunkName: "component---src-pages-tender-table-js" */),
  "component---src-pages-term-condition-js": () => import("./../../../src/pages/term_condition.js" /* webpackChunkName: "component---src-pages-term-condition-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-pages-text-animation-js": () => import("./../../../src/pages/text-animation.js" /* webpackChunkName: "component---src-pages-text-animation-js" */),
  "component---src-pages-update-of-week-js": () => import("./../../../src/pages/update_of_week.js" /* webpackChunkName: "component---src-pages-update-of-week-js" */),
  "component---src-pages-updateoftheweek-js": () => import("./../../../src/pages/updateoftheweek.js" /* webpackChunkName: "component---src-pages-updateoftheweek-js" */),
  "component---src-pages-vdvk-success-story-meghalaya-js": () => import("./../../../src/pages/vdvk-success-story-meghalaya.js" /* webpackChunkName: "component---src-pages-vdvk-success-story-meghalaya-js" */),
  "component---src-pages-website-policy-js": () => import("./../../../src/pages/website_policy.js" /* webpackChunkName: "component---src-pages-website-policy-js" */),
  "component---src-pages-week-update-js": () => import("./../../../src/pages/week_update.js" /* webpackChunkName: "component---src-pages-week-update-js" */),
  "component---src-pages-who-is-who-js": () => import("./../../../src/pages/Who_is_who.js" /* webpackChunkName: "component---src-pages-who-is-who-js" */),
  "component---src-templates-adverticement-js": () => import("./../../../src/templates/adverticement.js" /* webpackChunkName: "component---src-templates-adverticement-js" */),
  "component---src-templates-display-blog-js": () => import("./../../../src/templates/display_blog.js" /* webpackChunkName: "component---src-templates-display-blog-js" */),
  "component---src-templates-display-collectives-js": () => import("./../../../src/templates/display_collectives.js" /* webpackChunkName: "component---src-templates-display-collectives-js" */),
  "component---src-templates-display-focus-js": () => import("./../../../src/templates/display_focus.js" /* webpackChunkName: "component---src-templates-display-focus-js" */),
  "component---src-templates-display-page-js": () => import("./../../../src/templates/display_page.js" /* webpackChunkName: "component---src-templates-display-page-js" */),
  "component---src-templates-display-successstory-js": () => import("./../../../src/templates/display_successstory.js" /* webpackChunkName: "component---src-templates-display-successstory-js" */),
  "component---src-templates-recruitment-js": () => import("./../../../src/templates/recruitment.js" /* webpackChunkName: "component---src-templates-recruitment-js" */),
  "component---src-templates-tender-js": () => import("./../../../src/templates/tender.js" /* webpackChunkName: "component---src-templates-tender-js" */)
}

